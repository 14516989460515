<template>
  <basic-container>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('cip.plat.sys.defaultConfig.title.indexHeadTitle')"
                 @head-add="headAdd"
                 @head-delete="handDelete">
    </head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      v-model="searchForm"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset">
    </grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @gird-handle-select-click="selectionChange"
      @grid-row-detail-click="rowView">
      <template #customBtn="{row}">
        <el-button
          style="margin: 0 3px"
          v-if="row.status == 1 || row.status == 3"
          type="text"
          size="small"
          @click="rowEdit(row)"
        >编辑
        </el-button>
        <el-button
          style="margin: 0 3px"
          v-if="row.status == 1 || row.status == 3"
          type="text"
          size="small"
          @click="rowRemove(row)"
        >删除
        </el-button>
        <el-button
          style="margin: 0 3px"
          v-if="row.status == 1 || row.status == 3"
          type="text"
          size="small"
          @click="rowEnable(row)"
        >启用
        </el-button>
        <el-button
          style="margin: 0 3px"
          v-if="row.status == 2"
          type="text"
          size="small"
          @click="rowDisable(row)"
        >关闭
        </el-button>
      </template>
    </grid-layout>
  </basic-container>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getList, remove, submit} from "@/api/system/defaultConfig";
import {getSysConfigToUserInfo} from "@/api/system/user";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      selectionList: [],
      searchForm: {},
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      searchColumns: [
        {
          prop: "configName",
          width: 100,
          span:4,
          placeholder: this.$t("cip.plat.sys.defaultConfig.field.configName"),
        },
        {
          prop: "themeClass",
          type: "select",
          width: 100,
          span:4,
          dataType: 'String',
          props: {
            label: "dictValue",
            value: "dictValue"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=theme_color",
          placeholder: this.$t("cip.plat.sys.defaultConfig.field.themeClass"),
        },
        {
          prop: "status",
          type: "select",
          width: 100,
          span:4,
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=config_status",
          placeholder: this.$t("cip.plat.sys.defaultConfig.field.isEnable"),
        },
      ],
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      // if (this.permission.client_add) {
      result.push({
        label: this.$t('cip.cmn.btn.addBtn'),
        emit: "head-add",
        type: "button",
        icon: ""
      });
      // }
      // if (this.permission.client_delete) {
      result.push({
        label: this.$t('cip.cmn.btn.delBtn'),
        emit: "head-delete",
        type: "button",
        icon: ""
      });
      // }
      return result;
    },
    tableOptions() {
      return {
        linklabel: "configName",
        column: [
          {
            label: this.$t('cip.plat.sys.defaultConfig.field.configName'),
            prop: "configName",
            overHidden: true,
            placeholder: this.$t("cip.plat.sys.defaultConfig.field.configName"),
          },
          {
            label: this.$t('cip.plat.sys.defaultConfig.field.navigateMode'),
            prop: "navigateMode",
            type: "select",
            overHidden: true,
            dataType: 'String',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=navigate_mode",
            placeholder: this.$t("cip.plat.sys.defaultConfig.field.navigateMode"),
          },
          {
            label: this.$t("cip.plat.sys.defaultConfig.field.colorBlock"),
            prop: "colorBlock",
            type: "select",
            overHidden: true,
            dataType: 'String',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=color_block",
            placeholder: this.$t("cip.plat.sys.defaultConfig.field.colorBlock"),
          },
          {
            label: this.$t("cip.plat.sys.defaultConfig.field.themeClass"),
            prop: "themeClass",
            type: "select",
            dataType: 'String',
            props: {
              label: "dictValue",
              value: "dictValue"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=theme_color",
            placeholder: this.$t("cip.plat.sys.defaultConfig.field.themeClass"),
          },
          {
            label: this.$t("cip.plat.sys.defaultConfig.field.isEnable"),
            prop: "status",
            type: "select",
            span:4,
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=config_status",
            placeholder: this.$t("cip.plat.sys.defaultConfig.field.isEnable"),
          },
        ]
      }
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    headAdd() {
      this.$router.push({
        path: '/system/defaultConfig/add',
        query: {
          type: 'add',
        }
      });
    },
    rowEdit(row) {
      this.$router.push({
        path: '/system/defaultConfig/edit',
        query: {
          id: row.id,
          type: 'edit',
        }
      })
    },
    rowView(row) {
      this.$router.push({
        path: '/system/defaultConfig/view',
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      if (this.selectionList.filter(e => e.status === 2).length > 0) {
        this.$message.warning(this.$t('cip.plat.sys.defaultConfig.msg.config'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess")
          });
          this.$refs.crud.toggleSelection();
        });
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log(this.selectionList,"666")
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    rowEnable(row){
      row.status = 2
      submit(row).then((res) => {
        if (res.data.code === 200) {
          this.onLoad(this.page);
          this.$message({
            message: "启用成功",
            type: "success",
          });
        }
      })
    },
    rowDisable(row){
      getSysConfigToUserInfo().then(res => {
          if (res.data.code === 200) {
            if (row.id === res.data.data.default_config_id){
              this.$message({
                message: "系统配置正在使用该默认配置",
                type: "warning",
              });
            }else {
              row.status = 3
              submit(row).then((res) => {
                if (res.data.code === 200) {
                  this.onLoad(this.page);
                  this.$message({
                    message: "关闭成功",
                    type: "success",
                  });
                }
              })
            }
          } else {
            this.$message({
              message: "查询系统配置失败",
              type: "warning",
            });
          }
      })
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        })
    },
  }
};
</script>

<style>
</style>
